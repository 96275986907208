import "./style.css";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import DefaultAvatar from "../../images/profile.png";
import axios from "axios";
import moment from "moment";

import { useDispatch } from "react-redux";
import { setDriverData } from "../../state/action-creators/driverData";

function DriverProfile(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [driver, setDriver] = useState({});
  const { slug } = useParams();

  const dispatch = useDispatch();

  const access_token = localStorage.getItem("access_token");

  const submitOperation = (e) => {
    if (driver) {
      // localStorage.setItem("driver", JSON.stringify(driver));
      dispatch(setDriverData(driver));
    }
    if (access_token !== null) {
      setTimeout(() => {
        navigate(`/offer/${driver?.id}/new`);
      }, 800);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    getUserDetails();
    // eslint-disable-next-line
  }, []);

  const getUserDetails = () => {
    setLoader(true);
    axios
      .get("driver/" + slug)
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
          setDriver(res?.data?.data);
        }
      })
      .catch((err) => {
        setLoader(false);
        alert(err?.response?.data?.message);
      });
  };
  const getDriverExperience = (created_at) => {
    //const currentDate = moment(created_at).format("DD/MM/YYYY");
    //const today = moment().format("DD/MM/YYYY");

    //let currentDate = moment(created_at,"DD/MM/YYYY");
    //let today = moment().startOf('day');

    var startDate = moment(created_at);
    var endDate = moment();
    var yearDiff = endDate.diff(startDate, "years");
    if (yearDiff > 0) {
      return yearDiff + " years";
    }
    var monthDiff = endDate.diff(startDate, "months");
    if (monthDiff > 0) {
      return monthDiff + " months";
    }
    var dayDiff = endDate.diff(startDate, "days");
    if (dayDiff > 0) {
      return dayDiff + " days";
    }
  };

  return (
    <div className="App">
      <div className="profileScreen">
        {loader ? (
          <div className="mt-5">
            <span
              className="spinner-border text-primary"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        ) : Object.keys(driver).length > 0 ? (
          <div className="mobContainer">
            <Card>
              <div className="profileSetting">
                <div className="imageDisplay">
                  <img
                    src={driver?.small_image_url || DefaultAvatar}
                    className="imageSource"
                    alt={driver?.first_name}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center flex-column ">
                <div
                  className={`pt-1 ${
                    driver?.is_online === 1 ? "text-success" : "text-danger"
                  }`}
                >
                  {driver?.is_online === 1 ? "Online" : "Offline"}
                </div>
                <h3 className="fw-300 text-9">
                  {driver?.first_name + " " + driver?.last_name}
                </h3>
                <div className="detailList">
                  <span className="iconImages">
                    <FontAwesomeIcon
                      className="iconDriverHight"
                      icon={faStar}
                    />
                  </span>
                  <span>
                    {driver?.avg_rating ? driver?.avg_rating.toFixed(1) : "0.0"}
                  </span>
                </div>
                <div>
                  Total trips
                  <b className="px-1">{driver?.sum_of_total_trips || 0}</b>over
                  <b className="ps-1">
                    {getDriverExperience(driver.created_at)}
                  </b>
                </div>
              </div>
              <div className="userDetails">
                {driver?.truck?.photos?.length > 1 ? (
                  <Carousel variant="dark" touch={true}>
                    {driver?.truck?.photos?.map((data, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={data?.url}
                          alt={"image" + index}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                ) : (
                  driver?.truck?.photos?.length === 1 && (
                    <img
                      src={driver?.truck?.photos[0]?.url}
                      className="img-fluid pt-2"
                      alt={driver?.truck?.photos[0]?.url}
                    />
                  )
                )}
              </div>

              <button
                className="btn btn-dark shadow-none fw-400 button "
                type="submit"
                onClick={(event) => submitOperation(event)}
              >
                <span>Chat</span>
                {loader && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </Card>
          </div>
        ) : (
          <h3 className="fw-300 text-9 mt-5">No record found</h3>
        )}
      </div>
    </div>
  );
}

export default DriverProfile;
