import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import useDebouncedEffect from "use-debounced-effect";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import profilePic from "../../images/eliot.svg";

import {
  Avatar,
  Button as UiButton,
  ChatContainer,
  ConversationHeader,
  MessageList,
} from "@chatscope/chat-ui-kit-react";

import { handleResponse } from "../../utils";

const CreateNewMessage = ({
  setIsAddNewMessage,
  tripId,
  chatContainerStyle,
  handleBackClick,
  loginUser,
  logOut,
  setTripId,
  driverId,
  setCurrentTrip,
  setNewTrip,
  driver,
  setCurrentScreenMsg,
}) => {
  const [loader, setLoader] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  const [listOption, setListOption] = useState([]);
  const [placeText, setPlaceText] = useState("");

  const navigate = useNavigate();

  const initialValues = {
    pickup: "",
    drop: "",
    offer_amount: "",
    message: "",
    sms: false,
  };
  const validation = Yup.object().shape({
    message: Yup.string().required("Message is required."),
  });

  //------------- call offer send api ----------------------
  const sendTripMessage = (trip_id, values) => {
    axios
      .post("offers/send", {
        is_broadcast: 0,
        is_question: 1,
        message: values?.message,
        offer_amount: 0,
        payment_type: "cash",
        receiver_id: driverId,
        trip_id: trip_id,
      })
      .then((res) => {
        setLoader(false);
        setSnackbar(true);
        setIsAddNewMessage(false);
        setNewTrip(true);
        console.log("CREATE:: ", res?.data?.data);
        setCurrentScreenMsg(res?.data?.data, values?.message);
        setTripId(trip_id);
        navigate(`/offer/${driverId}/new/` + res?.data?.data?.offer?.id);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err?.response?.status);
        handleResponse(err);
      });
  };

  //------------- call create trip api ----------------------
  const submitOperation = (values, { setStatus, resetForm }) => {
    let tripResponse = null;
    setLoader(true);
    // alert(tripId);
    if (!Boolean(tripId)) {
      let location_name = `${
        values?.pickup?.place_name && values?.drop?.place_name
          ? values?.pickup?.place_name + " - " + values?.drop?.place_name
          : ""
      }`;
      axios
        .post(`trips/${Boolean(tripId) ? "update" : "create"}`, {
          destination_lat: values?.drop?.latitude,
          destination_long: values?.drop?.longitude,
          location_name: location_name,
          pick_up_lat: values?.pickup?.latitude,
          pick_up_long: values?.pickup?.longitude,
          trip_id: tripId || "",
          process_type: "web",
        })
        .then((res) => {
          tripResponse = res?.data?.data;
          setCurrentTrip(res?.data?.data?.location_name);
          let offer_amount = values?.offer_amount || 0;
          sendTripMessage(res?.data?.data?.id, values);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err?.response?.status);
          handleResponse(err);
        });
    } else {
      sendTripMessage(tripId, values);
    }
  };

  //----------------------- while create new trip call mapbox location api for pickup and droplocation on input change --------------------
  useDebouncedEffect(
    () => {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${placeText}.json?access_token=pk.eyJ1IjoiYWJ1YmFrYXJjb2RlcyIsImEiOiJjbDI3dGNxa20wMnNyM2VsZzhoOGZieG5lIn0.y4HniWvmZ-I9AqaqSJqxMA`
        )
        .then((res) => {
          if (res?.status === 200) {
            const list = res?.data?.features?.map((data) => {
              const value = {
                latitude: data?.geometry?.coordinates[0],
                longitude: data?.geometry?.coordinates[1],
                place_name: data?.place_name,
              };
              return { label: data?.place_name, value };
            });
            setListOption(list);
          } else {
            alert("Failed to get location");
          }
        })
        .catch((err) => {
          handleResponse(err);
        });
    },
    100,
    [placeText]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={(values, { setStatus, resetForm }) => {
        submitOperation(values, { setStatus, resetForm });
      }}
    >
      {(formik) => {
        const { values, errors, touched, handleChange, handleSubmit } = formik;
        return (
          <ChatContainer style={chatContainerStyle}>
            {snackbar && (
              <div class="alert alert-primary" role="alert">
                Offer sent successfully.
              </div>
            )}
            <ConversationHeader className="display_hide_menu">
              <ConversationHeader.Back onClick={handleBackClick} />

              <Avatar src={profilePic} size="md" />
              <ConversationHeader.Content>
                {loginUser}
              </ConversationHeader.Content>
              <ConversationHeader.Actions>
                <div>
                  <UiButton
                    onClick={(e) => logOut(e)}
                    icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                  />
                </div>
              </ConversationHeader.Actions>
            </ConversationHeader>
            <MessageList>
              <MessageList.Content>
                <div className="chatWithUser">
                  <div className="new_trip_data">
                    <div className="headingText">Send a New Message</div>
                    <form
                      className="formData"
                      id="submitData"
                      onSubmit={handleSubmit}
                    >
                      <textarea
                        type="text"
                        rows={3}
                        className="form-control border-dark input mb-2"
                        placeholder="Message"
                        id="message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      <span className="text-danger">
                        {touched.message && errors.message && errors.message}
                      </span>

                      <button
                        className="btn btn-dark shadow-none fw-400 button w-100"
                        type="submit"
                        disabled={loader}
                        // onClick={(event) => submitOperation(event)}
                      >
                        <span>Send Message</span>
                        {loader && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </MessageList.Content>
            </MessageList>
          </ChatContainer>
        );
      }}
    </Formik>
  );
};

export default CreateNewMessage;
