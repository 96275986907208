// import { useHistory } from "react-router";
export function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem) || window.innerWidth <= 676;
  });
}
// let history = useHistory();
export const handleResponse = (err) => {
  if (err?.response) {
    if (err?.response?.status === 401) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("user");
      // history.push({
      //   pathname: "/",
      // });
      window.location.href = "/";
    } else if (err?.response?.data?.message) {
      alert(
        Array.isArray(err?.response?.data?.message)
          ? err?.response?.data?.message[0]
          : err?.response?.data?.message
      );
    } else if (err?.response?.status === 500) {
      alert("Internal Server Error");
    } else {
      alert("There was an error");
    }
  } else {
    alert("Couldn't connect to server'.");
  }
};
