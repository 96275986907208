import React, { useState } from "react";
import { useSelector } from "react-redux";
import MobileForm from "./MobileForm";
import CodeVerify from "./CodeVerify";
import FirstName from "./FirstName";
import { useNavigate } from "react-router-dom";

const LoginUser = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  // const driver = JSON.parse(localStorage.getItem("driver"));

  const driver = useSelector((state) => state.driver.driver);

  const [data, setData] = useState({
    phone_code: "1",
    phone_number: "",
    otp: "",
    first_name: "",
  });

  // console.log(data);

  const submitForm = (newData) => {
    navigate(`/offer/${driver?.id}/new`);
    // history.push({
    //   pathname: "/offer/new",
    //   state: { candidateName, phone },
    // });
    // alert(newData);
  };

  const handleNextStep = (newData, finalStep = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (finalStep) {
      submitForm(newData);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };
  const steps = [
    <MobileForm next={handleNextStep} data={data} />,
    <CodeVerify next={handleNextStep} data={data} />,
    <FirstName next={handleNextStep} data={data} />,
  ];

  return <>{steps[currentStep]}</>;
};

export default LoginUser;
