const initialState = {
  driver: null,
};

const driver = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DRIVER_DATA": {
      return {
        driver: action.payload,
      };
    }

    default:
      return state;
  }
};

export default driver;
