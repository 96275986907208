import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";

import "./global.css";

import LoginUser from "./components/LoginUser/LoginUser";
import ChatScreen from "./components/ChatScreen/ChatScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import DriverProfile from "./components/Profile/DriverProfile";
import SelectDriver from "./components/SelectDriver";

function AppRoutes() {
  const location = useLocation();

  var myDynamicManifest = {
    name: "DRVR",
    short_name: "DRVR",
    display: "standalone",
    description: "Something dynamic",
    scope: ".",
    start_url: "." + location.pathname,
    background_color: "#000000",
    theme_color: "#0f4a73",
    icons: [
      {
        src: "whatever.png",
        sizes: "256x256",
        type: "image/png",
      },
    ],
  };
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  document
    .querySelector("#my-manifest-placeholder")
    .setAttribute("href", manifestURL);

  const navigate = useNavigate();
  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (e.key === "driver" && e.oldValue && !e.newValue) {
        navigate("/");
      } else if (e.key === "driver" && e.newValue) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  const access_token = localStorage.getItem("access_token");
  // const driver = localStorage.getItem("driver")
  //   ? JSON.parse(localStorage.getItem("driver"))
  //   : null;
  const driver = useSelector((state) => state.driver.driver);
  // console.log("driver", driver);
  if (access_token) {
    axios.defaults.headers.common["Authorization"] = access_token;
  }

  return (
    <Routes>
      <Route element={<HandleHome />} path="/" />
      <Route path="/select-driver" element={<SelectDriver />} />

      <Route path="/offer" element={<ProtectedRoute />}>
        <Route path=":driver_id" element={<ChatScreen />}>
          <Route path=":status" element={<ChatScreen />}>
            <Route index path=":slug" element={<ChatScreen />} />
          </Route>
        </Route>
      </Route>

      <Route path="/:slug" element={<DriverProfile />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;

const HandleHome = () => {
  const access_token = localStorage.getItem("access_token");
  // const driver = localStorage.getItem("driver")
  //   ? JSON.parse(localStorage.getItem("driver"))
  //   : null;
  const driver = useSelector((state) => state.driver.driver);
  return Boolean(access_token && driver) ? (
    <Navigate to={`/offer/${driver?.id}/new`} />
  ) : !Boolean(driver) ? (
    <Navigate to="/select-driver" />
  ) : (
    <LoginUser />
  );
};
