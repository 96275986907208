export const OFFER_STATUS = {
    createdByUser: 'Offer made',
    acceptedByUser: 'Offer accepted',
    acceptedByDriver: 'Offer accepted by driver',
    counteredByUser: 'Offer countered',
    counteredByDriver: 'Offer countered by driver',
    declinedByUser: 'Offer declined',
    declinedByDriver: 'Offer declined by driver',
    expired: 'expired',
    endedByUser: 'Offer ended',
    endedByDriver: 'Offer ended by driver',
    cancelledByUser: 'Offer cancelled',
    cancelledByDriver: 'Offer cancelled by driver',
    chatInitiated: 'Chat initiated',
    locationShared: 'Location shared',
    completed: 'Completed',
    reviewed: 'Reviewed',
}