import "./style.css";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import mobileImage from "../../images/otp-icon.png";
import Footer from "../Footer";
let timer;
function CodeVerify({ next, data }) {
  const [loader, setLoader] = useState(false);
  const [resendSecond, setResendSecond] = useState(60);

  const navigate = useNavigate();

  // const driver = JSON.parse(localStorage.getItem("driver"));

  const driver = useSelector((state) => state.driver.driver);

  const validation = Yup.object().shape({
    otp: Yup.string(),
  });

  useEffect(() => {
    if (resendSecond === 60) {
      timer = setInterval(() => {
        setResendSecond((_) => _ - 1);
      }, 1000);
    } else if (resendSecond === 0) clearTimeout(timer);
  }, [resendSecond]);

  // --------------------- otp varification api --------------------------
  const submitForm = (values, { setStatus, resetForm }) => {
    setLoader(true);
    axios
      .post("otp/verify", {
        otp: values?.otp,
        phone_code: data?.phone_code,
        phone_number: data?.phone_number,
        process_type: "web_minimal_login",
      })
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
          resetForm();
          if (res?.data?.data?.otp_token) {
            const otp_token = res?.data?.data?.otp_token;
            localStorage.setItem("otp_token", otp_token);

            // next(values, true); -------- comment on 21-7-2022 ------------
            next({ ...values, otp_token });
          } else {
            if (res?.data?.data?.access_token) {
              const access_token = "Bearer " + res?.data?.data?.access_token;
              localStorage.setItem("access_token", access_token);
              axios.defaults.headers.common["Authorization"] = access_token;
            }
            if (res?.data?.data?.user) {
              const user = res?.data?.data?.user;
              localStorage.setItem("user", JSON.stringify(user));
            }
            navigate(`/offer/${driver?.id}/new`);
          }
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response) {
          if (err?.response?.data?.message) {
            setStatus({
              error: err?.response?.data?.message,
            });
          }
        } else {
          setStatus({ error: "Couldn't connect to server" });
        }
      });
  };

  // ------------------ otp sent api ------------------------

  const handleOtpSent = (e) => {
    e.preventDefault();
    if (resendSecond) return;
    axios
      .post("otp/send", {
        phone_code: data.phone_code,
        phone_number: data.phone_number,
        type: "sms",
        process_type: "web_minimal_login",
      })
      .then((res) => {
        if (res?.data?.success) {
          // next(values);
          setResendSecond(60);
          alert(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.response) {
          if (err?.response?.data?.message) {
            alert(
              Array.isArray(err?.response?.data?.message)
                ? err?.response?.data?.message[0] ||
                    err?.response?.data?.message[1]
                : err?.response?.data?.message
            );
          }
        } else {
          alert("Couldn't connect to server");
        }
      });
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={validation}
      onSubmit={(values, { setStatus, resetForm }) => {
        submitForm(values, { setStatus, resetForm });
      }}
    >
      {(formik) => {
        const { values, status, setFieldValue, handleSubmit } = formik;

        return (
          <div className="App">
            <div className="chatWithUser">
              <div className="mobContainer">
                <Card>
                  <div style={{ height: "20vh" }}></div>

                  <h3 className="fw-300 text-9 mb-4">Verification</h3>
                  <p className="text-cente">
                    <img
                      className="img-fluid"
                      src={mobileImage}
                      alt="verification"
                    />
                  </p>
                  <p className="text-muted fw-300 mb-4">
                    Enter the verification code we sent to <br />
                    <span className="text-dark text-4">
                      {/* +1 *******179 */}
                      {"+" +
                        data?.phone_code +
                        " *******" +
                        data?.phone_number?.toString()?.slice(-3)}
                    </span>
                  </p>
                  <form
                    id="otp-screen"
                    onSubmit={handleSubmit}
                    className="otp_form"
                  >
                    <OtpInput
                      value={values.otp}
                      onChange={(value) => setFieldValue("otp", value)}
                      numInputs={6}
                      name="otp"
                      // separator={<span className="separator"></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid #CFD3DB",
                        borderRadius: "8px",
                        width: "52px",
                        height: "52px",
                        fontSize: "15px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue",
                      }}
                      focusStyle={{
                        border: "1px solid #000000",
                        outline: "none",
                      }}
                    />
                    <span className="text-danger">
                      {status && status?.error && status?.error}
                    </span>

                    <div className="d-grid my-4">
                      <button
                        className="btn btn-dark shadow-none fw-400 button"
                        type="submit"
                        disabled={Boolean(values?.otp?.length !== 6 || loader)}
                      >
                        <span>Verify</span>
                        {loader && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </div>
                  </form>
                  <div className="col-12 col-md-12 ml-auto">
                    <p className="text-end text-2 text-muted fw-300">
                      <a
                        className={`fw-300 ${
                          resendSecond
                            ? "text-secondary pe-none"
                            : "cursor-pointer text-primary"
                        }`}
                        href="#"
                        onClick={handleOtpSent}
                      >
                        Resend code
                      </a>
                      {!!resendSecond && ` in ${resendSecond} seconds `}
                    </p>
                  </div>
                </Card>
              </div>
              <Footer></Footer>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default CodeVerify;
