import "./style.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, Card } from "react-bootstrap";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Footer from "../Footer";
function MobileForm({ next, data }) {
  const [loader, setLoader] = useState(false);
  const driver = useSelector((state) => state.driver.driver);
  const [driverInfo, setDriverInfo] = useState(
    driver ? ` ${driver.first_name} ${driver.last_name}` : "Username"
  );

  const validation = Yup.object().shape({
    phone_code: Yup.string().required("Country code is required."),
    phone_number: Yup.string().required("Mobile number is required."),
  });

  const submitForm = (values, { setStatus, resetForm }) => {
    setLoader(true);
    axios
      .post("otp/send", {
        phone_code: values?.phone_code,
        phone_number: values?.phone_number,
        type: "sms",
        process_type: "web_minimal_login",
      })
      .then((res) => {
        if (res?.data?.success) {
          setLoader(false);
          next(values);
          resetForm();
        }
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response) {
          if (err?.response?.data?.message) {
            setStatus({
              error: Array.isArray(err?.response?.data?.message)
                ? err?.response?.data?.message[0]
                : err?.response?.data?.message,
            });
          }
        } else {
          setStatus({ error: "Couldn't connect to server" });
        }
      });
  };

  return (
    <Formik
      initialValues={data}
      validationSchema={validation}
      onSubmit={(values, { setErrors, setStatus, resetForm }) => {
        submitForm(values, { setErrors, setStatus, resetForm });
      }}
    >
      {(formik) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setStatus,
          status,
          setFieldValue,
        } = formik;
        return (
          <div className="App">
            <div className="chatWithUser">
              <div className="mobContainer">
                <Card>
                  <div style={{ height: "20vh" }}></div>
                  <h3 className="fw-300 text-9 mb-5">Chat With {driverInfo}</h3>
                  <form id="mobileForm" onSubmit={handleSubmit}>
                    <label htmlFor="phoneNumber" className="form-label fw-300">
                      Phone Number
                    </label>

                    <div className="d-flex">
                      <PhoneInput
                        country={"us"}
                        className={`w-25  ${
                          touched.phone_code && errors.phone_code
                            ? "border-danger"
                            : "border-light"
                        }`}
                        type="number"
                        id="phone"
                        name="phone_code"
                        onChange={(value) => {
                          setFieldValue("phone_code", value);
                          setStatus({ error: "" });
                        }}
                        value={values.phone_code}
                        inputProps={{
                          name: "phone_code",
                          country: "us",
                        }}
                        inputStyle={{
                          width: "100px",
                          height: "45px",
                        }}
                        inputClass="mobile-input"
                      />

                      <input
                        type="number"
                        className={`form-control bg-light w-75 ms-3 mobile-input ${
                          touched.phone_number && errors.phone_number
                            ? "border-danger"
                            : ""
                        }`}
                        placeholder="Enter Phone Number"
                        name="phone_number"
                        onChange={(e) => {
                          handleChange(e);
                          setStatus({ error: "" });
                        }}
                        value={values.phone_number}
                      />
                    </div>
                    <span className="text-danger">
                      {(touched.phone_code && errors.phone_code) ||
                      (status && status.error)
                        ? errors.phone_code || status.error
                        : touched.phone_number &&
                          errors.phone_number &&
                          errors.phone_number}
                    </span>
                    <div className="d-grid my-4">
                      <button
                        type="submit"
                        className="btn btn-dark shadow-none fw-400 button"
                        disabled={loader}
                      >
                        <span>Next</span>
                        {loader && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </div>
                  </form>
                </Card>
              </div>
              <Footer />
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default MobileForm;
