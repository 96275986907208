import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  const isAuthenticated = localStorage.getItem("access_token");

  return isAuthenticated && Outlet ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoute;
