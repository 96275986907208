const reactSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#212529",
    minHeight: "30px",
    height: "50px",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: "#212529",
      cursor: "pointer",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    // height: "50px",
    // padding: "4px 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "50px",
  }),
};

export default reactSelectStyle;
