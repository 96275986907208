import "../../App.css";
import "./style.css";
import React, { useCallback, useEffect, useState, useRef } from "react";

import Pusher from "pusher-js";
import axios from "axios";
import moment from "moment";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faLocationDot,
  faMessage,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import { Formik } from "formik";
// import * as Yup from "yup";
import Select, { components } from "react-select";
import reactSelectStyle from "./reactSelectStyle";

import DefaultAvatar from "../../images/profile.png";
import { Alert, Button } from "react-bootstrap";

import {
  Avatar,
  Button as UiButton,
  ChatContainer,
  Conversation,
  ConversationHeader,
  ConversationList,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageSeparator,
  SendButton,
  Sidebar,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

import { detectMob } from "../../utils";
import CreateTrip from "./CreateTrip";
import { OFFER_STATUS } from "../../constant";
import { handleResponse } from "../../utils";
import CreateNewMessage from "./CreateNewMessage";
import useDebouncedEffect from "use-debounced-effect";

import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import "mapbox-gl/dist/mapbox-gl.css";

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faLocationDot} size="lg" />
    </components.DropdownIndicator>
  );
};

const acceptedStatus = [
  "acceptedByDriver",
  "acceptedByUser",
  "locationShared",
  "chatInitiated",
];

const expiredStatus = [
  "declinedByDriver",
  "declinedByUser",
  "cancelledByDriver",
  "cancelledByUser",
  "expired",
];

const completedStatus = ["endedByUser", "endedByDriver", "completed"];

const newStatus = ["counteredByUser", "counteredByDriver"];

let mineApiController;
let conversationApiController;
let isOnlineApiController;
let smsUpdateApiController;

function ChatDesktop(props) {
  const user = JSON.parse(localStorage.getItem("user"));

  const tabList = [
    { label: "New", value: "new" },
    { label: "Accepted", value: "accepted" },
    { label: "Completed", value: "completed" },
    { label: "Expired", value: "expired" },
  ];
  const [loader, setLoader] = useState(false);
  const [offer_amount, setOffer_amount] = useState("");
  const [offerCountFlag, setOfferCountFlag] = useState(false);
  const [conversationID, setConversationID] = useState("");
  const [cancelLoader, setCancelLoader] = useState("");
  const [conversationLoader, setConversationLoader] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [currentTrip, setCurrentTrip] = useState({});
  const [tripId, setTripId] = useState("");
  const [newTrip, setNewTrip] = useState(false);
  const [conversation, setConversation] = useState({});
  const [messages, setMessages] = useState([]);
  const [driverProfile, setDriverProfile] = useState("");
  const [driverId, setDriverId] = useState();
  const [receiveMsg, setReceiveMsg] = useState(null);
  const [tripLocation, setTripLocation] = useState("");
  const [chatUserName, setChatUserName] = useState("");
  const [userIsOnline, setUserIsOnline] = useState(Boolean(user?.is_online));
  const [smsStatus, setSmsStatus] = useState(Boolean(user?.sms_subscribed));
  const [notification, setNotification] = useState(false);
  const [sending, setSending] = useState(false);
  const [redirectStatus, setRedirectStatus] = useState("");
  const [typingID, setTypingID] = useState("");
  const [pusherErrorFlag, setPusherErrorFlag] = useState(false);
  const [pusher, setPusher] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [liveDriver, setLiveDriver] = useState(null);
  const [blockLoader, setBlockLoader] = useState(false);

  const [sidebarVisible, setSidebarVisible] = useState(detectMob());
  const [sidebarStyle, setSidebarStyle] = useState({});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const [loginUser, setLoginUser] = useState("");

  const [isAddNewTrip, setIsAddNewTrip] = useState(false);
  const [isAddNewMessage, setIsAddNewMessage] = useState(false);
  const [showAddLocation, setShowAddLocation] = useState(false);
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});
  const [listOption, setListOption] = useState([]);
  const [placeText, setPlaceText] = useState("");
  const [tripLoader, setTripLoader] = useState(false);

  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const hasMoreRef = useRef(hasMore);
  let { driver_id, status, slug } = useParams();

  const prevCountRef = useRef();
  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevCountRef.current = driverId;
  }, [driverId]);

  mapboxgl.workerClass = MapboxWorker;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_MAP_API_KEY;

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(9);

  // ---------------- show driver location on map box ---------------------
  useEffect(() => {
    if (showMap) {
      // if (map.current) return; // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [lng, lat],
        zoom: zoom,
      });
      if (map.current) {
        new mapboxgl.Marker({ color: "red", draggable: false })
          .setLngLat([lng, lat])
          .addTo(map.current);
      }
    }
    // eslint-disable-next-line
  }, [showMap, lat, lng]);

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });
    // eslint-disable-next-line
  }, [map.current]);

  const initialValues = {
    pickup: "",
    drop: "",
    offer_amount: "",
    message: "",
    sms: false,
  };

  //----------------------- while create new trip call mapbox location api for pickup and droplocation on input change --------------------
  const onInputChange = (e) => {
    setPlaceText(e);
  };

  useDebouncedEffect(
    () => {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${placeText}.json?access_token=pk.eyJ1IjoiYWJ1YmFrYXJjb2RlcyIsImEiOiJjbDI3dGNxa20wMnNyM2VsZzhoOGZieG5lIn0.y4HniWvmZ-I9AqaqSJqxMA`
        )
        .then((res) => {
          if (res?.status === 200) {
            const list = res?.data?.features?.map((data) => {
              const value = {
                latitude: data?.geometry?.coordinates[0],
                longitude: data?.geometry?.coordinates[1],
                place_name: data?.place_name,
              };
              return { label: data?.place_name, value };
            });
            setListOption(list);
          } else {
            alert("Failed to get location");
          }
        })
        .catch((err) => {
          handleResponse(err);
        });
    },
    100,
    [placeText]
  );

  // back event for mobile View.
  const handleBackClick = () => {
    setSidebarVisible(!sidebarVisible);
    setChatUserName("");

    var { from } = location.state || { from: `/offer/${driver_id}/new` };

    navigate(from);
    setConversationID(""); // change here for slug change swap screen 25-07
  };

  useEffect(() => {
    let loginUser = user?.first_name || "";
    setLoginUser(loginUser);

    if (sidebarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "100%",
        maxWidth: "100%",
      });

      setConversationContentStyle({
        display: "flex",
      });
      setConversationAvatarStyle({
        marginRight: "1em",
      });
      setChatContainerStyle({
        display: "none",
      });
    } else {
      setSidebarStyle({});
      setChatContainerStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
    }
    // eslint-disable-next-line
  }, [
    sidebarVisible,
    setSidebarVisible,
    setSidebarStyle,
    setChatContainerStyle,
    setConversationContentStyle,
    setConversationAvatarStyle,
    setChatList,
  ]);

  //------------------ call user logout api --------------------------
  const logOut = (e) => {
    axios
      .post("logout", {})
      .then((r) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user");
        navigate("/");
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  const addNewTrip = (e) => {
    if (sidebarVisible) {
      setSidebarVisible(false);
    }
    setIsAddNewTrip(true);
    setIsAddNewMessage(false);
    if (chatUserName === "") {
      setChatUserName("Message 1");
    }
  };

  const addNewMessage = (e) => {
    if (sidebarVisible) {
      setSidebarVisible(false);
    }
    setIsAddNewMessage(true);
    setIsAddNewTrip(false);
    if (chatUserName === "") {
      setChatUserName("Message 1");
    }
  };

  const handleConversationClick = useCallback(
    (item) => {
      setConversationID(item?.conversation?.id);
      if (sidebarVisible) {
        setSidebarVisible(false);
      }
      if (item?.users[0]?.first_name !== "") {
        const userName =
          item?.users[0]?.first_name ||
          "" + " " + item?.users[0]?.last_name ||
          "";
        setChatUserName(userName);
        setIsAddNewTrip(false);
        setIsAddNewMessage(false);
      }
    },
    [sidebarVisible, setSidebarVisible]
  );
  // eslint-disable-next-line
  useEffect(() => {
    setPage(1);
    setChatList([]);
    setLoadingMore(false);
    setHasMore(true);
    hasMoreRef.current = true;
    listChat(false);
    // eslint-disable-next-line
  }, [status]);

  const onYReachEnd = () => {
    if (hasMoreRef.current) {
      setLoadingMore(true);
    }
  };

  useEffect(() => {
    if (hasMore === true && chatList?.length > 0 && loadingMore) {
      listChat();
      // setLoadingMore(false);
    } else {
      setLoadingMore(false);
    }
    // eslint-disable-next-line
  }, [loadingMore]);

  //------------------------ call user offer list api ----------------------------------------
  const listChat = (p = true) => {
    if (mineApiController) mineApiController?.abort();
    !p && !pusherErrorFlag && setLoader(true);
    mineApiController = new AbortController();
    const signal = mineApiController.signal;

    if (!Boolean(slug) && driver_id) {
      setDriverId(driver_id);
    }
    // alert("call listChat");
    if (Boolean(status)) {
      axios
        .get(`offers/mine?offer_status=${status}&driverId=${driver_id}`, {
          params: p && page > 1 && { page: page },
          signal,
        })
        .then((res) => {
          if (res?.data?.success) {
            // setChatList(res?.data?.data);
            if (p) {
              setChatList([...chatList, ...res?.data?.data]);
            } else {
              setChatList([...res?.data?.data]);
            }
            if (res?.data?.data?.length >= 10) {
              setHasMore(true);
              setLoadingMore(false);
              setPage(page + 1);
            } else {
              setLoadingMore(false);
              hasMoreRef.current = false;
              setHasMore(false);
            }
          }
          setLoader(false);
        })
        .catch((err) => {
          if (!axios.isCancel) {
            handleResponse(err);
          }
        });
    }
  };
  useEffect(() => {
    handleCurrentTrip();
  }, []);

  const handleCurrentTrip = () => {
    // setLoader(true);
    axios
      .get("trips/current")
      .then((res) => {
        if (res?.data?.success) {
          // setLoader(false);
          setCurrentTrip(res?.data?.data);
          setTripId(res?.data?.data?.id);
        }
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  useEffect(() => {
    setConversation({});
    // setChatUserName("");
    // setTripLocation("");
    if (showMap) {
      setShowMap(false);
    }
    if (slug) {
      setIsAddNewMessage(false);
      setIsAddNewTrip(false);
      setOfferCountFlag(false);
      setShowAddLocation(false);
      handleConversation();
      chatList?.map((chat) => {
        if (chat?.id === +slug) {
          setConversationID(chat?.conversation?.id);
        }
      });
    } else {
      setConversationID("");
      if (detectMob()) {
        setSidebarVisible(true); //-------------- for mobile screen back add 28-07 --------------
      }
    }
    // eslint-disable-next-line
  }, [slug]);

  //-------------------------- call user conversation with driver api ---------------------------------
  const handleConversation = (offer_id = "") => {
    if (conversationApiController) conversationApiController?.abort();

    !pusherErrorFlag && setConversationLoader(true);

    conversationApiController = new AbortController();
    const signal = conversationApiController.signal;

    axios
      .get(`offers/${slug || offer_id}/conversation`, { signal })
      .then((res) => {
        if (res?.data?.success) {
          setConversationLoader(false);
          setConversation(res?.data?.data);
          setConversationID(res?.data?.data?.conversation?.id);
          setTripId(res?.data?.data?.trip?.id);
          if (Object.keys(res?.data?.data?.trip)?.length > 0) {
            if (res?.data?.data?.trip?.location_name?.length > 0) {
              setTripLocation(res?.data?.data?.conversation?.name);
            } else {
              setTripLocation("");
            }
          }
          if (res?.data?.data?.users?.length > 0) {
            const user = res?.data?.data?.users;
            const userName = user[0]?.first_name + " " + user[0]?.last_name;
            setChatUserName(userName);
            setDriverProfile(user[0]?.small_image_url);
            setDriverId(user[0]?.id);
            // setDriverLocation({ lat: +user[0]?.lat, lng: +user[0]?.long });
            setLng(+user[0]?.long);
            setLat(+user[0]?.lat);
            if (newTrip) {
              setNewTrip(false);
              var new_list = chatList;
              let index = new_list.findIndex(
                (chat) => chat?.id === res?.data?.data?.id
              );
              if (index === -1) {
                setChatList([res?.data?.data, ...chatList]);
              } else {
                setChatList([
                  ...chatList?.map((chat) => {
                    if (chat?.id === res?.data?.data?.id) {
                      return res?.data?.data;
                    } else {
                      return chat;
                    }
                  }),
                ]);
              }
              // setChatList([res?.data?.data, ...chatList]);
            }
            console.log("Message List:::", res?.data?.data?.messages);
            setMessages(res?.data?.data?.messages);
            // redirectUser(res?.data?.data?.status);
          }
        }
      })
      .catch((err) => {
        setConversationLoader(false);
        if (!axios.isCancel) {
          handleResponse(err);
        }
      });
  };

  //------------ call api for user typing event in chat ----------------
  var canPublish = true;
  var throttleTime = 200;
  const handleTextChange = (e) => {
    const payload = {
      conversation_id: conversationID,
      receiver_id: driver_id,
      message_type: "typing",
    };

    if (canPublish) {
      axios
        .post(`messages/typing`, payload)
        .then((response) => {
          return response;
        })
        .catch(function (error) {
          console.log("error ----", error);
        });
      canPublish = false;
      setTimeout(function () {
        canPublish = true;
      }, throttleTime);
    }
  };

  //-------------------- call message send api --------------------------
  const sendMessage = (message) => {
    setSending(true);
    axios
      .post("messages/store", {
        conversation_id: conversationID,
        message: message.replaceAll("&nbsp;", ""),
        receiver_id: driver_id,
        sender_id: user?.id,
      })
      .then((res) => {
        if (res?.data?.success) {
          setSending(false);
          if (res?.data?.data) {
            console.log("SEND::", res?.data?.data);
            let list = [...chatList].map((e) => {
              if (e?.conversation?.id === res?.data?.data?.conversation_id) {
                e.conversation.last_message = res?.data?.data.message;
                e.status = res?.data?.data.offer.status;
              }
              return e;
            });
            setChatList(list);
            if (res?.data?.data?.offer?.id === +slug);
            setMessages([...messages, res?.data?.data]);
          }
        }
      })
      .catch((err) => {
        setSending(false);
        handleResponse(err);
      });
  };

  useEffect(() => {
    console.log("LIST:: ", chatList);
  }, [chatList]);
  //---------------------------------------- pusher -----------------------------------
  var clearInterval = 900; //0.9 seconds
  var clearTimerId;

  useEffect(() => {
    let authPoint = `${process.env.REACT_APP_API_ROOT_URL}broadcasting/auth`;
    const pusher = new Pusher("0e8dbcbdf03d5f6c9ff6", {
      cluster: "ap2",
      encrypted: true,
      authEndpoint: authPoint,
      auth: {
        headers: {
          Authorization: localStorage.getItem("access_token"),
        },
      },
    });

    const channel = pusher.subscribe("private-chat." + user?.id);
    setPusher(pusher);
    //--------------- for realtime receive msg -------------------
    channel.bind("chat-event", function (data) {
      console.log("RECEIVE::", data);
      setReceiveMsg(data?.message);
    });
    // ------------- for track driver typing event -------------
    channel.bind("typing-event", function (data) {
      console.log("typing-event", data?.offer?.conversation?.id);

      setTypingID(data?.offer?.conversation?.id);
      clearTimeout(clearTimerId);
      clearTimerId = setTimeout(function () {
        setTypingID("");
      }, clearInterval);
    });

    // ------------- for driver online/offline status -------------
    const driverStatusChannel = pusher.subscribe("presence-user-status-change");
    driverStatusChannel.bind("user-status-change-event", function (data) {
      console.log("user presence status", data);
      setLiveDriver(data);
    });
  }, []);

  //------------------ for driver live location ---------------------
  useEffect(() => {
    if (Boolean(driverId && conversationID)) {
      const driverLiveLocationChannel = pusher.subscribe(
        "private-location." + driverId
      );
      driverLiveLocationChannel.bind("location-event", function (data) {
        console.log("live location", data?.data);
        console.log("conversation", conversation);
        setLat(data?.data?.lat);
        setLng(data?.data?.long);
        setConversation((conversation) => {
          return {
            ...conversation,
            users: [
              {
                ...conversation["users"][0],
                lat: data?.data?.lat,
                long: data?.data?.long,
              },
            ],
          };
        });
      });
    }
    return () => {
      if (pusher) {
        pusher.unsubscribe("private-location." + prevCountRef.current);
      }
    };
  }, [driverId, conversationID]);

  useEffect(() => {
    if (liveDriver) {
      var list = chatList?.map((chat) => {
        if (chat?.users[0]?.id === liveDriver?.data?.id) {
          return {
            ...chat,
            users: [
              { ...chat["users"][0], is_online: liveDriver?.data?.is_online },
            ],
          };
        } else {
          return chat;
        }
      });
      console.log("list----", list);
      setChatList(list);
    }
    // eslint-disable-next-line
  }, [liveDriver]);

  useEffect(() => {
    if (pusher && pusher?.connection?.bind) {
      pusher.connection.bind("connected", function () {
        if (pusherErrorFlag) {
          setPusherErrorFlag(false);

          if (Boolean(conversationID)) {
            handleConversation();
          }
          listChat();
        }
      });

      pusher.connection.bind("error", function (error) {
        setPusherErrorFlag((prev) => (prev = true));
      });
      // pusher.connection.bind("initialized", function () {
      //   // alert("initialized");
      // });
      // pusher.connection.bind("connecting", function () {});
      // pusher.connection.bind("state_change", function (status) {});
    }
    // eslint-disable-next-line
  }, [pusher, pusherErrorFlag, conversationID]);

  // -------------------------------------------------

  useEffect(() => {
    if (receiveMsg) {
      if (receiveMsg?.conversation_id === conversationID) {
        setConversation({
          ...conversation,
          status: receiveMsg?.offer?.status,
          offer_counter: receiveMsg?.offer?.offer_counter,
        });
        var new_list = messages;
        let index = new_list.findIndex(
          (message) => message?.id === receiveMsg?.id
        );
        if (index === -1) {
          setMessages([...messages, receiveMsg]);
        } else {
          setMessages([
            ...messages?.map((message) => {
              if (message?.id === receiveMsg?.id) {
                return receiveMsg;
              } else {
                return message;
              }
            }),
          ]);
        }
      }

      if (receiveMsg?.conversation_id === conversationID) {
        let list = [...chatList].map((e) => {
          if (e.id === receiveMsg?.offer?.id) {
            return { ...e, ...receiveMsg?.offer };
          }
          return e;
        });
        setChatList(list);
        redirectUser(receiveMsg?.offer?.status);
      } else {
        let call = false;
        setNotification(true);
        if (newStatus.includes(receiveMsg?.offer?.status)) {
          setRedirectStatus("new");
        } else if (acceptedStatus.includes(receiveMsg?.offer?.status)) {
          setRedirectStatus("accepted");
        } else if (completedStatus.includes(receiveMsg?.offer?.status)) {
          setRedirectStatus("completed");
        } else if (expiredStatus.includes(receiveMsg?.offer?.status)) {
          setRedirectStatus("expired");
        }

        let receivedChatIndex = [...chatList].findIndex(
          (e) => e?.id === receiveMsg?.offer?.id
        );

        if (receivedChatIndex === -1) {
          switch (status) {
            case "new":
              if (newStatus.includes(receiveMsg?.offer?.status)) {
                call = true;
              }
              break;
            case "accepted":
              if (acceptedStatus.includes(receiveMsg?.offer?.status)) {
                call = true;
              }
              break;
            case "completed":
              if (completedStatus.includes(receiveMsg?.offer?.status)) {
                call = true;
              }
              break;
            case "expired":
              if (expiredStatus.includes(receiveMsg?.offer?.status)) {
                call = true;
              }
              break;
            // no default
          }
        }

        if (call) {
          listChat();
        } else {
          let new_list = [...chatList].filter((e) => {
            if (
              e.id === receiveMsg?.offer?.id &&
              ((status === "new" &&
                !newStatus.includes(receiveMsg?.offer?.status)) ||
                (status === "accepted" &&
                  !acceptedStatus.includes(receiveMsg?.offer?.status)) ||
                (status === "completed" &&
                  !completedStatus.includes(receiveMsg?.offer?.status)) ||
                (status === "expired" &&
                  !expiredStatus.includes(receiveMsg?.offer?.status)))
            ) {
            } else {
              return e;
            }
          });

          let list = [...new_list].map((e) => {
            if (e.id === receiveMsg?.offer?.id) {
              return { ...e, ...receiveMsg?.offer };
            }
            return e;
          });
          setChatList(list);
        }
      }
    }
    // eslint-disable-next-line
  }, [receiveMsg]);

  // const currentOffer = useMemo(() => {
  //   return chatList.find((e) => e.id === +slug) || {};
  // }, [slug, chatList]);

  const dateSeparator = (i, cur, prev) => {
    if (i === 0 || !moment(cur?.created_at).isSame(prev?.created_at, "day")) {
      const currentDate = moment(cur?.created_at).format("DD/MM/YYYY");
      const today = moment().format("DD/MM/YYYY");
      const yesterday = moment().subtract(1, "days").format("DD/MM/YYYY");
      return today === currentDate
        ? "Today"
        : yesterday === currentDate
        ? "Yesterday"
        : currentDate;
    }
    return "";
  };
  const inlineDate = (i, cur, prev) => {
    const curTime = moment(cur?.created_at).format("hh:mm A");
    const prevTime = moment(prev?.created_at).format("hh:mm A");
    if (i === 0 || curTime !== prevTime || cur?.sender_id !== prev?.sender_id)
      return curTime;
    return "";
  };
  // eslint-disable-next-line
  const offerTime = (data) => {
    const currentDate = moment(data?.created_at).format("DD/MM/YYYY");
    const today = moment().format("DD/MM/YYYY");
    return currentDate === today
      ? moment(data?.created_at).format("hh:mm A")
      : currentDate;
  };

  const redirectUser = (changed_status) => {
    if (acceptedStatus.includes(changed_status)) {
      navigate(`/offer/${driver_id}/accepted/${slug || ""}`);
    } else if (expiredStatus.includes(changed_status)) {
      navigate(`/offer/${driver_id}/expired/${slug || ""}`);
    } else if (completedStatus.includes(changed_status)) {
      navigate(`/offer/${driver_id}/completed/${slug || ""}`);
    } else if (newStatus.includes(changed_status)) {
      navigate(`/offer/${driver_id}/new/${slug || ""}`);
    }
  };
  //---------------------- when offer cancel,counter,accept by user -------------------------------
  const makeOffer = (action, value) => {
    if (cancelLoader) return;
    setCancelLoader(action);
    axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("access_token");
    axios
      .post(`offers/make/${slug}`, {
        action,
        counter_amount: value,
        receiver_id: driverId,
      })
      .then((res) => {
        setMessages([...res.data.data.messages]);
        setConversation({
          ...conversation,
          status: res?.data?.data?.status,
          offer_counter: res?.data?.data?.offer_counter,
        });
        let list = [...chatList].map((e) => {
          if (e.id === res.data.data.id) {
            delete res.data.data.messages;
            return { ...e, ...res.data.data };
          }
          return e;
        });
        setChatList(list);
        redirectUser(action);
        setOfferCountFlag(false);
        setCancelLoader("");
      })
      .catch((err) => {
        setCancelLoader("");
        setOfferCountFlag(false);
        handleResponse(err);
      });
  };

  //------------------- call user online/offline status api ----------------------
  const handleUserStatus = (value) => {
    // console.log("online", value);
    if (isOnlineApiController) isOnlineApiController?.abort();
    setUserIsOnline(!userIsOnline);
    isOnlineApiController = new AbortController();
    const signal = isOnlineApiController.signal;
    axios
      .post("user/online/status", { is_online: value }, { signal })
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            const user = res?.data?.data;
            setUserIsOnline(Boolean(user?.is_online));
            localStorage.setItem("user", JSON.stringify(user));
          }
        }
      })
      .catch((err) => {
        if (!axios.isCancel) {
          setUserIsOnline(!value);
          handleResponse(err);
        }
      });
  };

  //------------------- call sms status api ----------------------
  const handleSmsStatus = (value) => {
    if (smsUpdateApiController) smsUpdateApiController?.abort();
    setSmsStatus(!smsStatus);
    smsUpdateApiController = new AbortController();
    const signal = smsUpdateApiController.signal;
    axios
      .post("user/sms/status", { sms_subscribed: value }, { signal })
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.data) {
            const user = res?.data?.data;
            setSmsStatus(Boolean(user?.sms_subscribed));
            localStorage.setItem("user", JSON.stringify(user));
          }
        }
      })
      .catch((err) => {
        if (!axios.isCancel) {
          setSmsStatus(!value);
          handleResponse(err);
        }
      });
  };
  //------------- call block user api ----------------------
  const blockUser = (chatUserName) => {
    if (window.confirm(`Do you really want to block ${chatUserName}?`)) {
      setBlockLoader(true);
      axios
        .post("blocked/add", { blocked_user: driverId })
        .then((res) => {
          if (res?.data?.success) {
            setBlockLoader(false);
            if (res?.data?.data) {
              setConversation({
                ...conversation,
                offer_by: {
                  ...conversation.offer_by,
                  blocked_users: res?.data?.data?.blocked_users,
                },
              });
            }
          }
        })
        .catch((err) => {
          setBlockLoader(false);
          handleResponse(err);
        });
    } else {
      return false;
    }
  };

  //------------- call unblock user api ----------------------
  const unBlockUser = (chatUserName) => {
    if (window.confirm(`Do you want to unblock ${chatUserName}?`)) {
      setBlockLoader(true);
      axios
        .post("blocked/remove", { blocked_user: driverId })
        .then((res) => {
          if (res?.data?.success) {
            setBlockLoader(false);
            if (res?.data?.data) {
              setConversation({
                ...conversation,
                offer_by: {
                  ...conversation.offer_by,
                  blocked_users: res?.data?.data?.blocked_users,
                },
              });
            }
          }
        })
        .catch((err) => {
          setBlockLoader(false);
          handleResponse(err);
        });
    } else {
      return false;
    }
  };

  // ---------------- call trip update api ---------------------
  const getTripForm = () => {
    return (
      <>
        <Formik
          initialValues={initialValues}
          // validationSchema={validation}
          onSubmit={(values, { setStatus, resetForm }) => {
            setTripLoader(true);
            axios
              .post(`trips/update`, {
                destination_lat: values?.drop?.latitude,
                destination_long: values?.drop?.longitude,
                location_name:
                  values?.pickup?.place_name + " - " + values?.drop?.place_name,
                pick_up_lat: values?.pickup?.latitude,
                pick_up_long: values?.pickup?.longitude,
                trip_id: tripId || "",
                conversation_id: conversationID || "",
              })
              .then((res) => {
                // setCurrentTrip(res?.data?.data?.location_name);
                if (res?.data?.data?.location_name?.length > 0) {
                  setTripLocation(
                    res?.data?.data?.location_name +
                      " " +
                      new Date(res?.data?.data?.updated_at)
                  );
                  setConversation({ ...conversation, trip: res?.data?.data });
                  let list = [...chatList].map((e) => {
                    if (e.conversation.id === conversationID) {
                      return {
                        ...e,
                        trip: res?.data?.data,
                        conversation: {
                          ...e.conversation,
                          name:
                            res?.data?.data?.location_name +
                            " " +
                            new Date(res?.data?.data?.updated_at),
                        },
                      };
                    }
                    return e;
                  });
                  setChatList(list);
                }
                // let offer_amount = values?.offer_amount || 0;
                setShowAddLocation(false);
                setTripLoader(false);
              })
              .catch((err) => {
                setTripLoader(false);

                handleResponse(err);
              });
          }}
        >
          {(formik) => {
            const { errors, touched, handleSubmit, setFieldValue } = formik;
            return (
              <form
                className="formData mt-3 location_form"
                id="submitData"
                onSubmit={handleSubmit}
              >
                <Select
                  className="basic-single mb-2"
                  styles={reactSelectStyle}
                  placeholder="Search pick up location"
                  name="pickup"
                  options={listOption}
                  onInputChange={onInputChange}
                  onChange={(value) => setFieldValue("pickup", value?.value)}
                  noOptionsMessage={() => "Please enter pick up location"}
                  isClearable
                  components={{
                    Placeholder,
                    DropdownIndicator,
                  }}
                />
                <span className="text-danger">
                  {touched.pickup && errors.pickup && errors.pickup}
                </span>
                <Select
                  className="basic-single mb-2"
                  styles={reactSelectStyle}
                  placeholder="Search drop location"
                  name="drop"
                  onInputChange={onInputChange}
                  options={listOption}
                  noOptionsMessage={() => "Please enter drop location"}
                  onChange={(value) => setFieldValue("drop", value?.value)}
                  isClearable
                  components={{
                    Placeholder,
                    DropdownIndicator,
                  }}
                />
                <span className="text-danger">
                  {touched.drop && errors.drop && errors.drop}
                </span>
                <div className="d-flex justify-content-center w-100">
                  <button
                    className="btn btn-success shadow-none fw-500 btn-sm counter-btn mr-5"
                    disabled={!!cancelLoader}
                  >
                    <span>Save</span>
                    {tripLoader && (
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </>
    );
  };

  const setCurrentScreenMsg = (data, message) => {
    if (!conversationID) {
      // setNewTrip(true);
      setConversationID(data?.conversation?.id);
      handleConversation(data?.offer?.id);
      const { offer, ...newMessage } = data;
      setMessages([...messages, newMessage]);
    } else if (
      data?.conversation?.id === conversationID ||
      data?.conversation_id === conversationID
    ) {
      setConversationID(data?.conversation?.id || data?.conversation_id);
      const { offer, ...newMessage } = data;
      setMessages([...messages, newMessage]);
    }
  };

  return (
    <div className="multi_chat_container">
      {notification && (
        <div className="d-flex justify-content-center">
          <Alert
            variant="success"
            onClose={() => setNotification(false)}
            dismissible
            className="position-absolute notification"
          >
            <p className="mb-0">
              You have a new message from driver &nbsp;
              {receiveMsg?.offer?.users[0]?.username} <br />
              <Link
                to={`/offer/${driver_id}/${redirectStatus}/${
                  receiveMsg?.offer?.id || ""
                }`}
                onClick={() => setNotification(false)}
                className="alert-success alert-link "
              >
                Click here to go to the offer
              </Link>
            </p>
          </Alert>
        </div>
      )}

      <MainContainer
        className={detectMob() && !sidebarVisible ? "swap_screen" : ""}
        responsive
      >
        <Sidebar position="left" scrollable={false} style={sidebarStyle}>
          <ConversationHeader>
            <Avatar src={user?.small_image_url || DefaultAvatar} size="md" />
            <ConversationHeader.Content>
              <h5>{loginUser}</h5>
            </ConversationHeader.Content>
            <ConversationHeader.Actions>
              <div>
                <UiButton
                  onClick={(e) => logOut(e)}
                  icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                />
              </div>
            </ConversationHeader.Actions>
          </ConversationHeader>
          <div>
            <div className="d-flex justify-content-between align-items-center px-2 pt-1 pb-1">
              <div className="form-check form-switch ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="isOnline"
                  name="isOnline"
                  //   value={values.sms}
                  checked={userIsOnline}
                  onChange={(e) => {
                    handleUserStatus(e.target.checked);
                  }}
                />
                <label htmlFor="isOnline">
                  {user?.is_online === 1 ? "Online" : "Offline"}
                </label>
              </div>
              <div className="form-check form-switch ms-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="sms"
                  name="sms"
                  // value={smsStatus}
                  checked={smsStatus}
                  onChange={(e) => {
                    handleSmsStatus(e.target.checked);
                  }}
                />
                <label htmlFor="sms">SMS Update</label>
              </div>
            </div>
            <div className="tab-div">
              {tabList.map((tab, index) => {
                return (
                  <span
                    key={tab.value + index}
                    onClick={() => {
                      if (tab.value !== status) {
                        navigate(`/offer/${driver_id}/${tab.value}`, {
                          state: { from: location.pathname },
                        });
                      }
                    }}
                    className={`tab-item ${
                      tab.value === status ? "active" : ""
                    }`}
                  >
                    {tab.label}
                  </span>
                );
              })}
            </div>
          </div>
          {loader ? (
            <div className="d-flex justify-content-center mt-2">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <ConversationList
              loadingMore={hasMore && loadingMore}
              onYReachEnd={onYReachEnd}
            >
              {chatList?.length > 0 ? (
                chatList.map((item, index) => {
                  return (
                    <Conversation
                      active={+slug === item?.id}
                      key={index}
                      onClick={() => {
                        handleConversationClick(item);
                        navigate(`/offer/${driver_id}/${status}/${item?.id}`, {
                          state: { from: location.pathname },
                        });
                      }}
                      // lastActivityTime={offerTime(item)}
                      name={
                        <>
                          {item?.users?.length > 0 && (
                            <b className="username">
                              {item?.users[0]?.first_name +
                                " " +
                                item?.users[0]?.last_name}
                            </b>
                          )}
                          <div
                            style={{
                              wordBreak: "break-word",
                              display: "flex",
                            }}
                          >
                            <b className="location">
                              {item?.conversation?.name}
                            </b>
                          </div>
                        </>
                      }
                      info={
                        <>
                          <div>{item?.conversation?.last_message}</div>

                          {Boolean(typingID) &&
                            typingID === item?.conversation?.id && (
                              <TypingIndicator content="Typing" />
                            )}
                          <UiButton className="chatInButton mt-1" border>
                            {OFFER_STATUS[item?.status]}
                          </UiButton>
                        </>
                      }
                    >
                      <Avatar
                        src={item?.users[0]?.small_image_url || DefaultAvatar}
                        name={item?.status}
                        status={item?.users[0]?.is_online ? "available" : "dnd"}
                        style={conversationAvatarStyle}
                      />
                      <Conversation.Content
                        className="message_list"
                        style={conversationContentStyle}
                      >
                        <UiButton className="chatInButton" border>
                          Offer Made
                        </UiButton>
                      </Conversation.Content>
                    </Conversation>
                  );
                })
              ) : (
                <div className="d-flex justify-content-center">
                  No offer found
                </div>
              )}
            </ConversationList>
          )}

          <div className="d-flex w-100 p-1 mt-auto">
            <button
              className="btn btn-dark shadow-none fw-400 button-width"
              type="button"
              onClick={(event) => addNewTrip(event)}
            >
              <span>New Trip</span>
            </button>
            &nbsp;
            <button
              className="btn btn-dark shadow-none fw-400 button-width"
              type="button"
              onClick={(event) => addNewMessage(event)}
            >
              <span>New Chat</span>
            </button>
          </div>
        </Sidebar>
        {isAddNewTrip ? (
          <CreateTrip
            setChatList={setChatList}
            setCurrentTrip={setCurrentTrip}
            // tripId={tripId}
            driverId={driverId}
            setTripId={setTripId}
            setIsAddNewTrip={setIsAddNewTrip}
            chatContainerStyle={chatContainerStyle}
            handleBackClick={handleBackClick}
            loginUser={loginUser}
            logOut={logOut}
            listChat={listChat}
            handleCurrentTrip={handleCurrentTrip}
            setNewTrip={setNewTrip}
            // driver={driver}
            setCurrentScreenMsg={setCurrentScreenMsg}
          />
        ) : isAddNewMessage ? (
          <CreateNewMessage
            setChatList={setChatList}
            setCurrentTrip={setCurrentTrip}
            // tripId={tripId}
            driverId={driverId}
            setTripId={setTripId}
            setIsAddNewMessage={setIsAddNewMessage}
            chatContainerStyle={chatContainerStyle}
            handleBackClick={handleBackClick}
            loginUser={loginUser}
            logOut={logOut}
            listChat={listChat}
            handleCurrentTrip={handleCurrentTrip}
            setNewTrip={setNewTrip}
            // driver={driver}
            setCurrentScreenMsg={setCurrentScreenMsg}
          />
        ) : Boolean(slug) ? (
          <div className={`chat-container ${status === "new" && "new-status"}`}>
            <ChatContainer style={chatContainerStyle}>
              <ConversationHeader className="chat-header">
                <ConversationHeader.Back onClick={handleBackClick} />
                <Avatar src={driverProfile || DefaultAvatar} name="Zoe" />
                <ConversationHeader.Content
                  className="chat-header-content"
                  userName={
                    <>
                      {chatUserName}
                      <div style={{ wordBreak: "break-word", display: "flex" }}>
                        <p className="location mb-0">{tripLocation}</p>
                      </div>
                      {Boolean(typingID) && typingID === conversationID && (
                        <TypingIndicator content="Typing" />
                      )}
                    </>
                  }
                />
                {Object.keys(conversation)?.length > 0 && (
                  <ConversationHeader.Actions>
                    {conversation?.offer_by?.blocked_users?.includes(
                      conversation?.users[0]?.id?.toString()
                    ) ? (
                      <button
                        className="btn btn-success shadow-none fw-500 btn-sm counter-btn me-0"
                        disabled={blockLoader}
                        onClick={() => unBlockUser(chatUserName)}
                      >
                        Unblock
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger shadow-none fw-500 btn-sm counter-btn me-0"
                        disabled={blockLoader}
                        onClick={() => blockUser(chatUserName)}
                      >
                        Block
                      </button>
                    )}
                  </ConversationHeader.Actions>
                )}
              </ConversationHeader>
              <MessageList>
                {status === "new" && (
                  <div className={"new-offer-div"}>
                    <Button
                      className={"pe-none disabled btn-sm mb-2"}
                      variant={"dark"}
                    >
                      Waiting for{" "}
                      {conversation?.status !== "counteredByDriver"
                        ? `${chatUserName}'s`
                        : "your"}{" "}
                      response...
                    </Button>
                    <h4>
                      Your Offer Amount - $
                      {conversation?.amount?.toFixed(2) || 0.0}
                    </h4>
                    <h5>
                      Once {chatUserName} accepts your offer, you can chat.
                    </h5>

                    <div className={"d-flex justify-content-center"}>
                      {Object?.keys(conversation)?.length > 0 &&
                        !conversation?.trip?.location_name && (
                          <button
                            className="btn btn-info shadow-none fw-500 btn-sm counter-btn mr-5"
                            disabled={!!cancelLoader}
                            onClick={() => {
                              setShowAddLocation(!showAddLocation);
                            }}
                          >
                            <span>Add Trip Detail</span>
                          </button>
                        )}
                      {(conversation?.status === "declinedByDriver" ||
                        conversation?.status === "counteredByDriver") &&
                        conversation?.offer_counter?.[
                          conversation?.offer_counter?.length - 1
                        ]?.counter_amount && (
                          <>
                            <button
                              className="btn btn-success shadow-none fw-500 btn-sm counter-btn mr-5"
                              disabled={!!cancelLoader}
                              onClick={() => makeOffer("acceptedByUser")}
                            >
                              <span>
                                Accept for $
                                {
                                  conversation?.offer_counter?.[
                                    conversation?.offer_counter?.length - 1
                                  ]?.counter_amount
                                }
                              </span>
                              {cancelLoader === "acceptedByUser" && (
                                <span
                                  className="spinner-border spinner-border-sm ms-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>

                            <button
                              className="btn btn-dark shadow-none fw-500 btn-sm counter-btn mr-5"
                              disabled={!!cancelLoader}
                              onClick={() => setOfferCountFlag(!offerCountFlag)}
                            >
                              <span>Custom Amount</span>
                              {cancelLoader === "counteredByUser" && (
                                <span
                                  className="spinner-border spinner-border-sm ms-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </button>
                          </>
                        )}

                      <button
                        className="btn btn-danger shadow-none fw-500 counter-btn"
                        disabled={!!cancelLoader}
                        style={{ marginRight: "0 !important" }}
                        onClick={() => makeOffer("cancelledByUser")}
                      >
                        <span>Cancel</span>
                        {cancelLoader === "cancelledByUser" && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                    </div>

                    {offerCountFlag && (
                      <div className="d-flex mt-2 align-items-center">
                        <input
                          type="number"
                          autoComplete={"off"}
                          className="form-control border-dark input mb-2 mr-1"
                          placeholder="Offer counter amount"
                          id="offer_amount"
                          name="offer_amount"
                          disabled={!!cancelLoader}
                          value={offer_amount}
                          onChange={(e) =>
                            setOffer_amount(e.target.valueAsNumber)
                          }
                        />
                        <SendButton
                          disabled={!offer_amount || !!cancelLoader}
                          onClick={() =>
                            makeOffer("counteredByUser", offer_amount)
                          }
                        />
                      </div>
                    )}
                    {Boolean(showAddLocation) && getTripForm()}
                  </div>
                )}
                {status === "accepted" && (
                  <div className={"new-offer-div"}>
                    <div className={"d-flex justify-content-center"}>
                      {Object?.keys(conversation)?.length > 0 &&
                        !conversation?.trip?.location_name && (
                          <button
                            className="btn btn-info shadow-none fw-500 btn-sm counter-btn mr-5"
                            disabled={!!cancelLoader}
                            onClick={() => setShowAddLocation(!showAddLocation)}
                          >
                            <span>Add Trip Detail</span>
                          </button>
                        )}
                      {conversation?.status === "chatInitiated" && (
                        <>
                          <button
                            className="btn btn-dark shadow-none fw-500 btn-sm counter-btn mr-5"
                            disabled={!!cancelLoader}
                            onClick={() => setOfferCountFlag(!offerCountFlag)}
                          >
                            <span>Custom Amount</span>
                            {cancelLoader === "counteredByUser" && (
                              <span
                                className="spinner-border spinner-border-sm ms-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </button>
                        </>
                      )}

                      <button
                        className="btn btn-danger shadow-none fw-500 counter-btn"
                        disabled={!!cancelLoader}
                        onClick={() => makeOffer("cancelledByUser")}
                      >
                        <span>Cancel</span>
                        {cancelLoader === "cancelledByUser" && (
                          <span
                            className="spinner-border spinner-border-sm ms-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        )}
                      </button>
                      <button
                        className="btn shadow-none fw-500 counter-btn find-btn"
                        disabled={!!cancelLoader}
                        onClick={() => setShowMap(true)}
                      >
                        <span>Find driver</span>
                      </button>
                    </div>

                    {offerCountFlag && (
                      <div className="d-flex mt-2 align-items-center">
                        <input
                          type="number"
                          autoComplete={"off"}
                          className="form-control border-dark input mb-2 mr-1"
                          placeholder="Offer counter amount"
                          id="offer_amount"
                          name="offer_amount"
                          disabled={!!cancelLoader}
                          value={offer_amount}
                          onChange={(e) =>
                            setOffer_amount(e.target.valueAsNumber)
                          }
                        />
                        <SendButton
                          disabled={!offer_amount || !!cancelLoader}
                          onClick={() =>
                            makeOffer("counteredByUser", offer_amount)
                          }
                        />
                      </div>
                    )}
                    {Boolean(showAddLocation) && getTripForm()}
                  </div>
                )}

                {showMap && (
                  <>
                    <div className="d-flex w-100">
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="#bfccdf"
                        size="lg"
                        className="ms-auto text-danger"
                        onClick={() => setShowMap(false)}
                      />
                    </div>
                    <div>
                      <div ref={mapContainer} className="map-container" />
                    </div>
                  </>
                )}

                <div className={"message-container"}>
                  {conversationLoader ? (
                    <div className="d-flex justify-content-center mt-2">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    Array.isArray(messages) &&
                    messages?.length > 0 &&
                    messages.map((data, index, arr) => (
                      <React.Fragment key={index}>
                        {dateSeparator(index, data, arr[index - 1]) && (
                          <MessageSeparator
                            content={dateSeparator(index, data, arr[index - 1])}
                          />
                        )}
                        <Message
                          key={index}
                          model={{
                            message: data?.message,
                            sentTime: data?.created_at,
                            sender: "Zoe",
                            direction:
                              data?.sender_id === user.id
                                ? "outgoing"
                                : "incoming",
                            position: "single",
                          }}
                        >
                          {inlineDate(index, data, arr[index - 1]) && (
                            <Message.Footer
                              className={"message-time"}
                              sentTime={inlineDate(index, data, arr[index - 1])}
                            />
                          )}
                        </Message>
                      </React.Fragment>
                    ))
                  )}
                </div>
              </MessageList>
              {status === "accepted" && (
                <MessageInput
                  className="message_text_box"
                  placeholder={sending ? "Sending..." : "Type message here"}
                  attachButton={false}
                  onSend={(textContent) => sendMessage(textContent)}
                  onChange={(textContent) => handleTextChange(textContent)}
                />
              )}
            </ChatContainer>
          </div>
        ) : (
          !detectMob() && (
            <ChatContainer style={chatContainerStyle}>
              <MessageList>
                <MessageList.Content
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100vh",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon icon={faMessage} color="#bfccdf" />
                  <p className="pick-person">
                    Pick a person from left menu, <br />
                    and start your conversation.
                  </p>
                </MessageList.Content>
              </MessageList>
            </ChatContainer>
          )
        )}
      </MainContainer>
    </div>
  );
}

export default ChatDesktop;
